import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { isAndroid } from 'react-device-detect';
import moment from 'moment';

import {
  StyledTeamSquadContainer,
  StyledCard,
  StyledCardDetailItem,
  StyledValue,
  StyledLabel,
  StyledPlayerData,
  StyledChartContainer,
  StyledPlayerControlButton,
  StyledControlContainer,
  StyledButtonsContainer,
  StyledTeamName,
  StyledInjuredLabel,
  StyledLeftLabel,
  StyldRightLabel,
  StyledPlus,
  StyledHeader,
  StyledScoutingHeader,
  StyledLeftArrow,
  StyledMobileInfoHeader,
  StyledSwitchLabel,
  StyledSwitchContainer,
  StyledSwitchBlock,
  StyledInfoDark,
  StyledSidebarButton,
  StyledSliderWrapper,
  StyledParagraph,
  StyledNicknameButton,
  StyledNicknameButtonContainer,
  StyledNicknameLabel,
  StyledHeaderName,
  StyledSwitchWrapper,
  StyledToggleTeamView,
  StyledDarkReactTooltip,
  StyledPatronLabel,
  StyledInsight,
  StyledContractValue,
  StyledTimePerformanceContainer,
  StyledPerformanceIcon
} from './styled';
import { StyledReactTooltip, StyledTableLoader } from '../../../../../../globalStyled';
import { ReactComponent as Crown } from '../../../../../../assets/img/crown.svg';
import TeamSquadPopups from '../../../../../TeamSquadPopups';
import { DonutChart, GridChart } from '../../../../../Charts';
import ScoutResultTableContainer from '../../../ScoutResultTableContainer';
import SwitchToggle from '../../../../../SwitchToggle';
import { digitFormater, goToDiscordLogin } from '../../../../../../service/functions';
import { POPUPS, ratingRangeStatus } from '../../../../../../service/constants';

import {
  rangeColor,
  formRangeColor,
  transferFeelingColor,
  simplifiedPosition,
  strategyIds,
  mindsetStatusToValue,
  formRangeStatus,
  transferFeeling,
} from '../../../../../../service/data';
import { setDataForCardRequest } from '../../../../../../redux/actions/Transfers';
import { teamListPlayer, playerAccBoost, playerDelayDecline } from '../../../../../../redux/actions/Team';
import { sidebarOpenRightPanel } from '../../../../../../redux/actions/Sidebar';
import { showPopupWindow } from '../../../../../../redux/actions/Popups';

import { playerFromList, getSeasonNumber, getAcademyList } from '../../../../../../redux/selector/Team';
import { isPremiumUser, isAuth } from '../../../../../../redux/selector/User';

import { dataForSidebar } from '../../../../../../redux/selector/Sidebar';


const CARD_SECTIONS = {
  OVERVIEW: 'OVERVIEW',
  CONTRACT: 'CONTRACT',
  INSIGHTS: 'INSIGHTS',
}
export default function TeamSquad({ searchPage = false, isMobileView = false }) {
  const {
    CONFIRM_ACCELERATE_POPUP,
    CONFIRM_DECLINE_DELAY_POPUP,
    SCOUT_POPUP,
    TRANSFER_SEARCH_POPUP,
    LOAN_OFFER_POPUP,
    RELEASE_PLAYER_POPUP,
    ADD_NICKNAME_POPUP,
    CONFIRM_ACADEMY_POPUP,
    CONTRACT_EXTEND_POPUP,
    CONTRACT_OFFER_POPUP
  } = POPUPS;
  const dispatch = useDispatch();

  const [cardActiveSection, setCardActiveSection] = useState(CARD_SECTIONS.OVERVIEW);

  const selectedPlayerFromTable = useSelector(playerFromList);
  const { cash } = useSelector(dataForSidebar);

  const isPremium = useSelector(isPremiumUser);
  const seasonNumber = useSelector(getSeasonNumber);
  const academyList = useSelector(getAcademyList);
  const isAuthenticated = useSelector(isAuth);

  const changeCardActiveSection = (section) => {
    setCardActiveSection(section);
  };

  const goToPatreon = () => {
    if (window.navigator.userAgent.includes('wv') && isAndroid) {
      window.open(
        'inappsubscription://?package=com.example.app.webview-master&successful_url=https://www.theopenleague.com/home&expired_url=https://www.yahoo.com',
        '_blank'
      );
    } else {
      window.open('https://www.patreon.com/join/theopenleague?', '_blank');
    }
  };

  const functionForListing = (teamID, status) => {
    dispatch(teamListPlayer(teamID, status));
  };

  const openPopup = (popupName, id, isLoading=false) => {
    dispatch(showPopupWindow(id, popupName, isLoading));
  };

  const hideSidebar = () => {
    dispatch(sidebarOpenRightPanel(false));
  };

  const onAccelerateDevelopment = (id) => {
    dispatch(playerAccBoost(id));
  };
  const onDelayDecline = (id) => {
    dispatch(playerDelayDecline(id));
  };

  const sendScout = (id) => {
    if (!isAuthenticated) {
      goToDiscordLogin();
    } else {
      dispatch(showPopupWindow(id, SCOUT_POPUP, false));
    }
  };
  const sendFreeAgent = (id) => {
    dispatch(showPopupWindow(id, CONTRACT_OFFER_POPUP, false));
  };

  const mobileURL =
    window.navigator.userAgent.includes('wv') && isAndroid
      ? 'inappsubscription://?package=com.example.app.webview-master&successful_url=https://www.theopenleague.com/home&expired_url=https://www.yahoo.com'
      : 'https://www.patreon.com/theopenleague';

  const handleNicknameButtonClick = () =>
    isPremium
      ? dispatch(showPopupWindow(ADD_NICKNAME_POPUP, ADD_NICKNAME_POPUP, false))
      : window.open(mobileURL, '_blank');

  const showTransferSearchModal = (id) => {
    dispatch(showPopupWindow(id, TRANSFER_SEARCH_POPUP, false));
  };

  const loanOffer = () => {
    if (!isAuthenticated) {
      goToDiscordLogin();
    } else {
      dispatch(
        setDataForCardRequest({
          id: selectedPlayerFromTable._id,
          name: `${selectedPlayerFromTable.first_name} ${selectedPlayerFromTable.last_name}`,
          transfer_fee_offer: null,
        })
      );
      dispatch(showPopupWindow(LOAN_OFFER_POPUP, LOAN_OFFER_POPUP, false));
    }
  };

  const getRecoveryDate = (date) => {
    const eventdate = moment(date).utcOffset('-0000').add(1, 'days');
    const todaysdate = moment();
    const daysLeft = eventdate.diff(todaysdate, 'days');
    return `${daysLeft < 1 ? 1 : daysLeft} day${daysLeft > 1 ? 's' : ''}`;
  };



  if (selectedPlayerFromTable) {
    const reverseRating = [...selectedPlayerFromTable.media_rating];
    reverseRating.reverse();
    const reversePrevRating = [...selectedPlayerFromTable.previous_rating];
    reversePrevRating.reverse();

    const formRange = selectedPlayerFromTable.form_range
      ? formRangeStatus[selectedPlayerFromTable.form_range]
      : selectedPlayerFromTable.form;
    const potentialRange = selectedPlayerFromTable.potential_range
      ? ratingRangeStatus[selectedPlayerFromTable.potential_range]
      : selectedPlayerFromTable.potentialValue;
    const ratingRange = selectedPlayerFromTable.rating_range
      ? ratingRangeStatus[selectedPlayerFromTable.rating_range]
      : selectedPlayerFromTable.ratingValue;
    const isBoostDisabled = selectedPlayerFromTable.boost_enabled || selectedPlayerFromTable.isLoading || searchPage;
    const isDeclineDisabled =
      selectedPlayerFromTable.decline_enabled || selectedPlayerFromTable.isLoading || searchPage;
    const seasons = selectedPlayerFromTable.contract?.data?.length;
    const playTime = selectedPlayerFromTable.contract?.data?.play_time;
    const seasonsCount = selectedPlayerFromTable.contract?.current_stats.seasons_count;
    const isExtended = !isEmpty(selectedPlayerFromTable.contract?.extension);
    const playedGamesRaw = selectedPlayerFromTable.contract?.current_stats.played_games;
    const playedGames = (Math.trunc(playedGamesRaw*10)/10).toFixed(1);

    const metContractPlayTerms = selectedPlayerFromTable.contract?.met_contract_play_terms;
    const loanedIn =
      !isEmpty(selectedPlayerFromTable.lender_team) && !selectedPlayerFromTable.loanedOut;

    const seasonsLeft = seasons - seasonsCount;
    const getPlayTimePerformance = playTimeData => {
      const performanceList = [];
      for (let i = 0; i < playTimeData.length; i++) {
        if (!isEmpty(playTimeData)) {
          if (playTimeData[i] === 0) {
            performanceList.push(<StyledPerformanceIcon color='#d63c30' />)
          }
          else if (playTimeData[i] === 1) {
            performanceList.push(<StyledPerformanceIcon color='#00996b' />)
          }
          else {
            performanceList.push(<StyledPerformanceIcon color='#d9d9d9' />);
          }
        }

      }
      return performanceList;
    }
    return (
      <>
        {!searchPage && !isEmpty(selectedPlayerFromTable) && (
          <TeamSquadPopups
            selectedPlayer={selectedPlayerFromTable}
            cash={cash}
            onDelayDecline={onDelayDecline}
            onAccelerateDevelopment={onAccelerateDevelopment}
            isFreeAgentPopup={false}
            seasonsLeft={seasonsLeft}
          />
        )}
        <StyledTeamSquadContainer>
          <StyledHeader isMobileView={isMobileView}>
            {isMobileView && (
              <StyledMobileInfoHeader
                onClick={() => {
                  hideSidebar();
                }}
              >
                <StyledLeftArrow />
                Player information
              </StyledMobileInfoHeader>
            )}
            <StyledTeamName isPremium={isPremium}>{selectedPlayerFromTable.teamName}</StyledTeamName>
            {selectedPlayerFromTable.name && (
              <StyledHeaderName searchPage={searchPage}>{selectedPlayerFromTable.name}</StyledHeaderName>
            )}

            {!searchPage && (
              <StyledNicknameButtonContainer>
                {!selectedPlayerFromTable.nickName ? (
                  <StyledNicknameButton
                    isPremium={isPremium}
                    onClick={isPremium ? () => handleNicknameButtonClick() : undefined}
                  >
                    {!isPremium ? (
                      <>
                        <StyledDarkReactTooltip
                          globalEventOff="click"
                          id="nickname"
                          type="dark"
                          clickable
                          effect="solid"
                          place="bottom"
                        >
                          <StyledPatronLabel onClick={() => goToPatreon()}>Become a patron</StyledPatronLabel> for easier access to player insights. Subscribe using our mobile application.
                        </StyledDarkReactTooltip>
                        <span data-tip data-event="click" clickable data-for="nickname">
                          Add nickname
                        </span>
                      </>
                    ) : (
                      <>Add nickname</>
                    )}
                  </StyledNicknameButton>
                ) : (
                  <StyledNicknameLabel>"{selectedPlayerFromTable.nickName}"</StyledNicknameLabel>
                )}
              </StyledNicknameButtonContainer>
            )}
            <StyledToggleTeamView className="formations-section">
              <button
                className={cardActiveSection === CARD_SECTIONS.OVERVIEW ? 'active' : ''}
                onClick={() => changeCardActiveSection(CARD_SECTIONS.OVERVIEW)}
                type="button"
              >
                Overview
              </button>
              {!searchPage && <button
                className={cardActiveSection === CARD_SECTIONS.CONTRACT ? 'active' : ''}
                onClick={() => changeCardActiveSection(CARD_SECTIONS.CONTRACT)}
                type="button"
              >
                Contract
              </button>}
              <button
                disabled={!isPremium}
                className={cardActiveSection === CARD_SECTIONS.INSIGHTS ? 'active' : ''}
                onClick={() => changeCardActiveSection(CARD_SECTIONS.INSIGHTS)}
                type="button"
              >
                <StyledDarkReactTooltip
                  globalEventOff="click"
                  id="insights"
                  type="dark"
                  clickable
                  effect="solid"
                  place="bottom"
                >
                  <StyledPatronLabel onClick={() => goToPatreon()}>Become a patron</StyledPatronLabel> for easier access to player insights. Subscribe using our mobile application.
                </StyledDarkReactTooltip>
                <StyledInsight
                  {...(!isPremium && {
                    'data-tip': true,
                    'data-event': 'click',
                    clickable: true,
                    'data-for': 'insights',
                  })}
                >
                  Insights
                  <Crown />
                </StyledInsight>
              </button>
            </StyledToggleTeamView>
          </StyledHeader>
          <StyledCard>
            {selectedPlayerFromTable.form === 'Injured' && (
              <StyledInjuredLabel type="injured">
                <StyledLeftLabel>
                  <StyledPlus>+</StyledPlus>Player is injured
                </StyledLeftLabel>
                <StyldRightLabel>Returns in: {getRecoveryDate(selectedPlayerFromTable.recovery_time)}</StyldRightLabel>
              </StyledInjuredLabel>
            )}
            {(academyList.includes(selectedPlayerFromTable._id) || selectedPlayerFromTable.academy?.status === 1) && (
              <StyledInjuredLabel type="academy">
                <StyledLeftLabel>Academy</StyledLeftLabel>
                <StyldRightLabel>Returns at the end of season</StyldRightLabel>
              </StyledInjuredLabel>
            )}
            {selectedPlayerFromTable.loanedOut && (
              <StyledInjuredLabel type="loanedOut">
                <StyledLeftLabel type="loanedOut">Loaned out</StyledLeftLabel>
                <StyldRightLabel>Returns in: {getRecoveryDate(selectedPlayerFromTable.returnDate)}</StyldRightLabel>
              </StyledInjuredLabel>
            )}
            {selectedPlayerFromTable.lender_team && (
              <StyledInjuredLabel type="loanedIn">
                <StyledLeftLabel type="loanedIn">Leaves in</StyledLeftLabel>
                <StyldRightLabel>{getRecoveryDate(selectedPlayerFromTable.lender_team.returnDate)}</StyldRightLabel>
              </StyledInjuredLabel>
            )}
            {cardActiveSection === CARD_SECTIONS.OVERVIEW && (
              <>
                <StyledPlayerData>
                  <StyledCardDetailItem half topBorder>
                    <>
                      <StyledValue>{simplifiedPosition[selectedPlayerFromTable.fav_position] ?? 'N/A'}</StyledValue>
                      <StyledLabel>Position</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem half topBorder>
                    <>
                      <StyledValue>{selectedPlayerFromTable.age ?? 'N/A'}</StyledValue>
                      <StyledLabel>Age</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color={rangeColor[ratingRange]}>{ratingRange ?? 'N/A'}</StyledValue>
                      <StyledLabel>Rating</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color={rangeColor[potentialRange]}>{potentialRange ?? 'N/A'}</StyledValue>
                      <StyledLabel>Potential</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color={formRangeColor[formRange]}>{formRange ?? 'N/A'}</StyledValue>
                      <StyledLabel>Fitness</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledChartContainer>
                  {reverseRating.map((item, index) => (
                    <DonutChart key={`${item}${index}`} index={index + 1} arcSize={item} />
                  ))}
                </StyledChartContainer>
                <StyledChartContainer className="grid-chart-section">
                  <GridChart
                    currentRating={selectedPlayerFromTable.ratingValue}
                    seasonNumber={seasonNumber}
                    data={reversePrevRating}
                  />
                </StyledChartContainer>
                <StyledPlayerData className="key-statistics-section">
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>
                        {Number.isInteger(selectedPlayerFromTable.goals_scored)
                          ? selectedPlayerFromTable.goals_scored
                          : '-'}
                      </StyledValue>
                      <StyledLabel>Goals</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>
                        {Number.isInteger(selectedPlayerFromTable.assists) ? selectedPlayerFromTable.assists : '-'}
                      </StyledValue>
                      <StyledLabel>Assists</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    {selectedPlayerFromTable.teamName !== 'Free agent' ? (
                      <>
                        <StyledValue>
                          {Number.isInteger(selectedPlayerFromTable.played_matches)
                            ? selectedPlayerFromTable.played_matches
                            : '-'}
                        </StyledValue>
                        <StyledLabel>Starts</StyledLabel>
                      </>
                    ) : (
                      <>
                        <StyledValue>{selectedPlayerFromTable.played_matches_total || '-'}</StyledValue>
                        <StyledLabel>Career starts</StyledLabel>{' '}
                      </>
                    )}
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>
                        {selectedPlayerFromTable.teamName === 'Free agent'
                          || (selectedPlayerFromTable.potential_min === -1
                            && selectedPlayerFromTable.potential_max === -1)
                          ? 'N/A'
                          : `$${digitFormater(selectedPlayerFromTable.potential_min)}-${digitFormater(
                            selectedPlayerFromTable.potential_max
                          )}`}
                      </StyledValue>
                      <StyledLabel>Est. Value</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>${digitFormater(selectedPlayerFromTable.salary / 7, 0) || '-'}</StyledValue>
                      <StyledLabel>Daily Salary</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                {!isMobileView &&
                  // remove condition to show acces dev for loaned out players
                  !selectedPlayerFromTable.lender_team && (
                    <>
                      <StyledSwitchWrapper>
                        <StyledSwitchBlock className="player-programs-section">
                          <StyledSwitchContainer>
                            <StyledSwitchLabel>
                              Accelerate development
                              <StyledReactTooltip id="accelerate" type="light" effect="solid" place="bottom">
                                This program will accelerate your player’s development until peak performance is reached
                              </StyledReactTooltip>
                              <StyledInfoDark iconsize="11" data-tip clickable data-for="accelerate" />
                            </StyledSwitchLabel>
                            <SwitchToggle
                              isOnAndOffLabelVisible
                              viewMode={searchPage}
                              id="acc-development"
                              toggled={selectedPlayerFromTable.boost_enabled}
                              onChange={() => {
                                if (!isAuthenticated) {
                                  goToDiscordLogin();
                                } else {
                                  openPopup(CONFIRM_ACCELERATE_POPUP, selectedPlayerFromTable._id);
                                }
                              }}
                              isDisabled={isBoostDisabled || selectedPlayerFromTable.loanedOut}
                            />
                          </StyledSwitchContainer>
                          <StyledSwitchContainer>
                            <StyledSwitchLabel>
                              Delay decline
                              <StyledReactTooltip id="decline" type="light" effect="solid" place="bottom">
                                This program will delay your player’s decline until the player retires
                              </StyledReactTooltip>
                              <StyledInfoDark iconsize="11" data-tip clickable data-for="decline" />
                            </StyledSwitchLabel>
                            <SwitchToggle
                              isOnAndOffLabelVisible
                              viewMode={searchPage}
                              id="delay-decline"
                              toggled={selectedPlayerFromTable.decline_enabled}
                              onChange={() => {
                                if (!isAuthenticated) {
                                  goToDiscordLogin();
                                } else {
                                  openPopup(CONFIRM_DECLINE_DELAY_POPUP, selectedPlayerFromTable._id);
                                }
                              }}
                              isDisabled={isDeclineDisabled || selectedPlayerFromTable.loanedOut}
                            />
                          </StyledSwitchContainer>
                        </StyledSwitchBlock>
                        <StyledSwitchBlock className="player-list-section">
                          {!searchPage && !selectedPlayerFromTable.loanedOut && (
                            <StyledSwitchContainer>
                              <StyledSwitchLabel>
                                List for sale
                                <StyledReactTooltip id="list-for-sale" type="light" effect="solid" place="bottom">
                                  Player will be added to the transfer list
                                </StyledReactTooltip>
                                <StyledInfoDark iconsize="11" data-tip clickable data-for="list-for-sale" />
                              </StyledSwitchLabel>
                              <SwitchToggle
                                isOnAndOffLabelVisible
                                id="for-sale"
                                toggled={selectedPlayerFromTable.listed}
                                onChange={() => {
                                  if (!isAuthenticated) {
                                    goToDiscordLogin();
                                  } else {
                                    functionForListing(selectedPlayerFromTable._id, !selectedPlayerFromTable.listed);
                                  }
                                }}
                                isDisabled={selectedPlayerFromTable.isLoading}
                              />
                            </StyledSwitchContainer>
                          )}
                        </StyledSwitchBlock>
                      </StyledSwitchWrapper>
                      {selectedPlayerFromTable &&
                        !selectedPlayerFromTable.myTeam &&
                        !selectedPlayerFromTable.offerToBuyLoading &&
                        selectedPlayerFromTable.academy?.status !== 1 &&
                        searchPage && (
                          <StyledControlContainer>
                            <StyledSidebarButton
                              primary
                              className={selectedPlayerFromTable.newOffer && `clicked`}
                              name={selectedPlayerFromTable.name}
                              onClick={() => {
                                if (!isAuthenticated) {
                                  goToDiscordLogin();
                                } else {
                                  const { transfer, newOffer, teamName, _id } = selectedPlayerFromTable;
                                  if (transfer === undefined && !newOffer && teamName !== 'Free agent') {
                                    showTransferSearchModal(_id);
                                  }
                                  if (teamName === 'Free agent') {
                                    sendFreeAgent(_id);
                                  }
                                }
                              }}
                            >
                              {selectedPlayerFromTable.teamName === 'Free agent' ? 'Sign free agent' : 'Buy offer'}
                            </StyledSidebarButton>

                            {selectedPlayerFromTable.teamName !== 'Free agent' &&
                              selectedPlayerFromTable.academy?.status !== 1 && (
                                <StyledSidebarButton primary onClick={() => loanOffer()}>
                                  Loan offer
                                </StyledSidebarButton>
                              )}
                          </StyledControlContainer>
                        )}

                      {searchPage &&
                        selectedPlayerFromTable.teamName === 'Free agent' &&
                        selectedPlayerFromTable.academy?.status !== 1 && (
                          <>
                            <StyledSliderWrapper searchPage={searchPage}>
                              <StyledScoutingHeader>Scouting</StyledScoutingHeader>
                              {isEmpty(selectedPlayerFromTable.scout_results) ? (
                                <div>
                                  <StyledParagraph>
                                    A scout hasn’t been sent yet. Scout the player to learn their rating, potential, and
                                    fitness.
                                  </StyledParagraph>
                                </div>
                              ) : (
                                <>
                                  <ScoutResultTableContainer scoutData={selectedPlayerFromTable.scout_results} />
                                </>
                              )}
                            </StyledSliderWrapper>
                            <StyledSidebarButton
                              disabled={selectedPlayerFromTable.ongoing_scout}
                              primary
                              onClick={() => sendScout(selectedPlayerFromTable._id)}
                            >
                              {isEmpty(selectedPlayerFromTable.scout_results) ? 'Scout player' : 'Re-scout'}
                            </StyledSidebarButton>
                          </>
                        )}
                    </>
                  )}
              </>
            )}
            {
              cardActiveSection === CARD_SECTIONS.CONTRACT && !searchPage && (
                <>
                  <StyledPlayerData>
                    <StyledCardDetailItem oneThird>
                      <>
                        <StyledContractValue>{seasons > 1 ? `${seasons} seasons` : `${seasons} season` ?? 'N/A'}</StyledContractValue>
                        <StyledLabel>Contract</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem oneThird>
                      <>
                        <StyledContractValue>{playTime ?? 'N/A'}</StyledContractValue>
                        <StyledLabel>Matches</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem oneThird>
                      <>
                        <StyledContractValue>${digitFormater(selectedPlayerFromTable.salary / 7) ?? 'N/A'}</StyledContractValue>
                        <StyledLabel>Per day</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  <StyledPlayerData>
                    <StyledCardDetailItem oneThird>
                      <>

                        <StyledContractValue color={seasonsLeft < 2 ? '#d63c30' : '#00996b'}>{`${seasonsLeft} ${(seasonsLeft) > 1 ? " seasons" : " season"}` ?? 'N/A'}</StyledContractValue>
                        <StyledLabel>Left</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem oneThird>
                      <>
                        <StyledContractValue color={playedGames < playTime ? '#d63c30' : '#00996b'}>{playedGames ?? 'N/A'}</StyledContractValue>
                        <StyledLabel>Matches this season</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem oneThird>
                      <>
                        <StyledTimePerformanceContainer>
                          {getPlayTimePerformance(metContractPlayTerms)}
                        </StyledTimePerformanceContainer>
                        <StyledLabel>Play time performance</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  {(!academyList.includes(selectedPlayerFromTable._id) ||
                    selectedPlayerFromTable.academy?.status !== 1) &&
                    !searchPage &&
                    (
                      <StyledButtonsContainer>
                        {/* Show release only for regular players */}
                        {!selectedPlayerFromTable.loanedOut && !selectedPlayerFromTable.lender_team && <StyledPlayerControlButton
                          className="release-section"
                          primary
                          onClick={() => {
                            if (!isAuthenticated) {
                              goToDiscordLogin();
                            } else {
                              openPopup(RELEASE_PLAYER_POPUP, selectedPlayerFromTable._id, true);
                            }
                          }}
                        >
                          Release
                        </StyledPlayerControlButton>}

                        {/* Don't show To academy for loanedOut players */}
                        {!selectedPlayerFromTable.loanedOut && (
                          <StyledPlayerControlButton
                            className="academy-section"
                            secondary
                            onClick={() => {
                              if (!isAuthenticated) {
                                goToDiscordLogin();
                              } else {
                                openPopup(CONFIRM_ACADEMY_POPUP, selectedPlayerFromTable._id);
                              }
                            }}
                          >
                            To Academy
                          </StyledPlayerControlButton>
                        )}

                        {/* Show extend for regular and loaned out players with one season left */}
                        {(!loanedIn && seasonsLeft === 1 && !isExtended) 
                        &&
                          <StyledPlayerControlButton
                            primary
                            onClick={() => {
                              openPopup(CONTRACT_EXTEND_POPUP, selectedPlayerFromTable._id)
                            }
                            }
                          >
                            Extend
                          </StyledPlayerControlButton>

                        }
                        {isExtended &&
                          <StyledPlayerControlButton nonClickable secondary>
                            Extended
                          </StyledPlayerControlButton>}
                      </StyledButtonsContainer> 
                    )}
                </>
              )
            }


            {cardActiveSection === CARD_SECTIONS.INSIGHTS && (
              <>
                <StyledPlayerData>
                  <StyledCardDetailItem topBorder half>
                    <>
                      <StyledValue>{isPremium && strategyIds[selectedPlayerFromTable.fav_formation]}</StyledValue>
                      <StyledLabel>Favourite Formation</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem topBorder half>
                    <>
                      <StyledValue>
                        {isPremium && simplifiedPosition[selectedPlayerFromTable.second_fav_position]}
                      </StyledValue>
                      <StyledLabel>Alternate Position</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem half>
                    <>
                      <StyledValue color="#00996b">
                        {isPremium && mindsetStatusToValue[selectedPlayerFromTable.fav_mindset]}
                      </StyledValue>
                      <StyledLabel>Favourite Mindset</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem half>
                    <>
                      <StyledValue color="#d63c30">
                        {isPremium && mindsetStatusToValue[selectedPlayerFromTable.worst_mindset]}
                      </StyledValue>
                      <StyledLabel>Worst Mindset</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color="#00996b">{isPremium && selectedPlayerFromTable.fav_opponent}</StyledValue>
                      <StyledLabel>Favourite Opponent</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color="#d63c30">{isPremium && selectedPlayerFromTable.worst_opponent}</StyledValue>
                      <StyledLabel>Worst Opponent</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                {Number(selectedPlayerFromTable?.transfer_feeling) ? (
                  <StyledPlayerData>
                    <StyledCardDetailItem>
                      <>
                        <StyledValue color={transferFeelingColor[selectedPlayerFromTable.transfer_feeling]}>
                          {isPremium && transferFeeling[selectedPlayerFromTable.transfer_feeling]}
                        </StyledValue>
                        <StyledLabel>Club Fit</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                ) : null}
              </>
            )}
          </StyledCard>
        </StyledTeamSquadContainer>
      </>
    );
  }
  return <StyledTableLoader type="Bars" color="#00BFFF" height={100} width={100} />;
}
